import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Container from '@atoms/Container';
import Heading from '@atoms/Heading';
import Text from '@atoms/Text';
import Button from '@atoms/Button';
import Link from 'next/link'

const EduHeroSection = ({
    title,
    desc,
    imgSrc,
    imgWidth = 464,
    imgHeight = 468,
    btnLabel = "Get Started",
    handleClick = () => { }
}) => {
    return (
        <section className="py-6  md:py-11 ">
            <Container type="type0">
                <div className=" md:flex items-center justify-between gap-10  ">
                    <div className='max-w-[680px]'>
                        <Heading type="subheading1"
                            className="text-gray-800 ">{title}</Heading>

                        <Text type='bodyCaption6' className="text-gray-800 mt-3 md:mt-6">{desc}</Text>
                        <Link href="https://www.lissun.app/connect-with-the-best-mental-health-counsellor-&-therapist-in-india">
                            <Button
                                rounded='rounded'
                                type="secondaryNeutral"
                                className="font-bold  !font-Nunito text-base py-2.5 md:px-6 px-4 w-full my-6 lg:my-8"
                                onClick={handleClick}
                                width='w-full md:w-[267px]'
                            >
                                {btnLabel}
                            </Button>
                        </Link>
                    </div>

                    <div>
                        <Image
                            src={imgSrc}
                            width={imgWidth}
                            height={imgHeight}
                            className=""
                            objectFit="cover"
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default EduHeroSection

EduHeroSection.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    imgSrc: PropTypes.string,
    imgWidth: PropTypes.number,
    imgHeight: PropTypes.number,
    btnLabel: PropTypes.string,
    handleClick: PropTypes.func,
};
