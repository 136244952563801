import Button from "@atoms/Button";
import Container from "@atoms/Container";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Plan from "../../molecules/EduPlan";
import Feedback from "./Feedback";
import AOS from "aos";
import { useRouter } from "next/router";
// import "aos/dist/aos.css";
import Carousel from "@molecules/Carousel";
import FeedbackForm from "./FeedbackForm";
import ResponseSubmit from "./ResponseSubmit";
import TopHeaderEdu from "./TopHeaderEdu";
import StudentIssues from "./StudentIssues";
import TailoredSupport from "./TailoredSupport";
import WhyLissun from "./WhyLissun";
import FooterBanner from "./FooterBanner";
import Footer from "./Footer";
import Gallery from "@components/YPay/Gallary";
import Head from "next/head";
import * as FullStory from "@fullstory/browser";
import Founder from "./Founder";
import Solutions from "./Solutions";
import StudentForm from "./StudentForm";
import { sendEventObj } from "utils/constants";
import EduHeroSection from "@organisms/EduHeroSection";
import EduMeetLissunErs from "@organisms/EduMeetLissunErs";
import EduStudentSuicide from "@organisms/EduStudentSuicide";
import EduLissunFeatures from "@organisms/EduLissunFeatures";
import EduPersonalizedJourney from "@organisms/EduPersonalizedJourney";
import EduPlanSection from "@organisms/EduPlanSection";
import EduStudentTestimonial from "@organisms/EduStudentTestimonial";
import EduCenterOfKnwoledge from "@organisms/EduCenterOfKnwoledge";
import Heading from "@atoms/Heading";
import PaymentSuccess from "./Components/PaymentSuccess";

const EducationLandingPage = () => {
  const productImage = [
    "/images/education/Banner31.png",
    "/images/education/Banner32.png",
    "/images/education/Banner33.png",
    "/images/education/Banner34.png",
    "/images/education/PWlogo35.svg",
    "/images/education/Banner36.png",
    "/images/education/Banner37.png",
    "/images/education/Banner38.png",
  ];

  const universityImages = [
    "/images/Inst2.png",
    "/images/Inst1.png",
    "/images/Inst3.png",
  ];

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,

      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  const feedBackImages = [
    "/images/F1.svg",
    "/images/F2.svg",
    "/images/F6.png",
    "/images/F8.svg",
    "/images/F5.svg",
    "/images/F3.svg",
    "/images/F7.svg",
    "/images/F4.svg",
  ];

  const scrolltoHash = function (element_id) {
    const element = document.getElementById(element_id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showStudentForm, setShowStudentForm] = useState(false);
  const [payType, setPayType] = useState("BASIC");
  const [paySuccessMsg, setPaySuccessMsg] = useState(false);

  const staticGallery = [
    {
      id: 1,
      displayName: "Student and Education",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2023-03-06T05:19:12.911Z1678079952911Classroom.jpeg",
      slug: "students-and-education",
    },
    {
      id: 2,
      displayName: "Manage Emotions",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:26:10.291Z1664177170291manage%20emotions.png",
      slug: "manage-emotions",
    },
    {
      id: 3,
      displayName: "Feel Better",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:42:04.114Z1664178124114feel%20better.png",
      slug: "feel-better",
    },
    {
      id: 4,
      displayName: "Life and Relationships",
      thumbnailUrl:
        "https://lissun-prod.s3.ap-south-1.amazonaws.com/Lissun/images/2022-09-26T07:41:54.338Z1664178114338life%20and%20relationship.png",
      slug: "life-and-relationship",
    },
  ];

  const onClickGalleryCont = (slug) => {
    window.open(`${process.env.NEXT_PUBLIC_MAIN_URL}/resources/${slug}`);
  };
  const router = useRouter();

  useEffect(() => {
    FullStory.init({
      orgId: process.env.NEXT_PUBLIC_FULLY_STORY_TRACKING_ID,
    });
    sendEventObj({ visits_education_key: true }, "user_visits_education");
    return () => {
      if (FullStory) FullStory.shutdown();
    };
  }, []);
  const onClickBuyNow = (type) => {
    sendEventObj(
      { viewed_lead_gen_form_key: "education" },
      "user_viewed_lead_gen_form"
    );
    setShowStudentForm(true);
    setPayType(type);
  };
  const onClose = (isClose) => {
    setShowStudentForm(false);
  };
  const removeQueryParam = (param) => {
    // Destructure the current query parameters
    const { query } = router;

    // Remove the specified query parameter
    delete query[param];

    // Push the new route without the removed query parameter
    router.push(
      {
        pathname: router.pathname,
        query: query,
      },
      undefined,
      { shallow: true }
    );
  };

  const closePaySuccessMsg = () => {
    setPaySuccessMsg(false);
    removeQueryParam("successMsg");
  };
  useEffect(() => {
    if (router.query.successMsg == "true") {
      setPaySuccessMsg(true);
      removeQueryParam("successMsg");
    }
    setTimeout(() => {
      closePaySuccessMsg();
    }, 30000);
  }, []);

  const studentReview = [
    {
      // title: "Highly Recommended",
      desc: "Yaha pe aake mujhe badia feel hua. Jo unhone bataya hai sleep cycle ke regarding mere liye benefit hoga thanks lissun",
      // imgSrc: "/images/education/profile-1.svg",
      // name: "Raman Joshi",
      // profession: "Student",
      // imgWidth: 55,
      // imgHeight: 55,
    },
    {
      desc: "5 Star Amazing session. I think that a good psychologist will change the mindset of a student. Will definitely help a student deal with problems and help clear exam",
    },
    {
      desc: "The time I came to lissun I was really sad and anxious but I felt all my problems got resolved. Now I am happily satisfied and the tips I got cover my concerns. I would like to thank you heavily",
    },
    {
      desc: "Best place for relaxing our mind…! Like when we are in stress leave out stress. Session was amazing!",
    },
    {
      desc: "Maine life mein first time kisi se apna worst experience share kiya aur jiss cheez se darr lagta tha woh kiya. Thank you, my life changed for good",
    },
    {
      desc: "Mujhe backlog aur relationship issues the aur pareshan ho gaya tha. Kisi ko nahin batana chahta tha but sir ne madad kari jab maine khul ke baat ki. Unhone acchi tarah se samjhaya aur mujhe apni problem ka solution mila",
    },
    {
      desc: "Mera performance bohot accha nahin tha aur panic attack aaya tha. Mujhe meditation aur techniques bataye aur follow karne pe accha result aa raha hai abh. Neend bhi poori ho rahi hai aur memory bhi better hai",
    },
  ];
  const featureList = [
    {
      icon: "/images/education/consultation1.png",
      title: "Personalized Emotional Strength Coaching",
    },
    {
      icon: "/images/education/discussion1.png",
      title: "Group Sessions with Qualified Experts",
    },
    {
      icon: "/images/education/package-box1.png",
      title: "Self Help Tools",
    },
    {
      icon: "/images/education/check-in1.png",
      title: "Regular Check-ins",
    },
    {
      icon: "/images/education/community1.png",
      title: "Student Community",
    },
    {
      icon: "/images/education/journal1.png",
      title: "Journal Prompts",
    },
    {
      icon: "/images/education/share1.png",
      title: "Resource Sharing",
    },
    {
      icon: "/images/education/assessment1.png",
      title: "Emotional Wellness Assessments",
    },
  ];
  const personalJourneyList = [
    {
      icon: "/images/card1.svg",
      title: "Customized Content  ",
    },
    {
      icon: "/images/card2.svg",
      title: "Hand Picked Therapists",
    },
    {
      icon: "/images/card3.svg",
      title: "Curated Events",
    },
    {
      icon: "/images/card4.svg",
      title: "Student Communities",
    },
    {
      icon: "/images/card5.svg",
      title: "Wellness Assessments",
    },
    {
      icon: "/images/card6.svg",
      title: "Journal Prompts",
    },
    {
      icon: "/images/card7.svg",
      title: "Meditation Recommendation",
    },
    {
      icon: "/images/card8.svg",
      title: "Special Packages",
    },
  ];

  const planList = [
    {
      planName: "BASIC",
      planPrice: 500,
      planFeatures: [
        { features: "1 Group Session" },
        { features: "1 Whatsapp Wellness Check" },
        { features: "Self Help Tools via the Lissun app" },
      ],
      appFeatures: [
        { features: "Meditation Audios" },
        { features: "Journaling" },
        { features: "Assessments" },
        { features: "Destressing Tools" },
        { features: "Sleep Audios" },
        { features: "And Much More" },
      ],
      onClickBuyNow: (value) => {
        setShowStudentForm(value);
      },
    },
    {
      planName: "STANDARD",
      planPrice: 750,
      planFeatures: [
        { features: "1 Personalized Coaching Sessions" },
        { features: "1 Group Sessions" },
        { features: "Personalized Wellness Plan" },
        { features: "Self Help Tools via the Lissun app" },
      ],
      appFeatures: [
        { features: "Meditation Audios  " },
        { features: "Journaling" },
        { features: "Assessments" },
        { features: "Destressing Tools" },
        { features: "Sleep Audios" },
        { features: "And Much  More" },
      ],
      onClickBuyNow: (value) => {
        setShowStudentForm(value);
      },
    },
    {
      planName: "PREMIUM",
      planPrice: 1000,
      planFeatures: [
        { features: "2 Personalized Coaching Sessions" },
        { features: "2 Group Sessions" },
        { features: "4 Whatsapp Wellness Checks" },
        { features: "Personalized Wellness Plan" },
        { features: "Self Help Tools via the Lissun app" },
      ],
      appFeatures: [
        { features: "Meditation Audios" },
        { features: "Journaling" },
        { features: "Assessments" },
        { features: "Destressing Tools" },
        { features: "Sleep Audios" },
        { features: "And Much  More" },
      ],
      onClickBuyNow: (value) => {
        setShowStudentForm(value);
      },
    },
  ];

  const eduCenterOfKnowledgeData = [
    {
      imageSrc: "/images/education/knw1.svg",
      title: "Build Academic Resilience",
      desc: "Equip yourself with the tools and wisdom to conquer academic challenges",
      link: "https://www.lissun.app/resources/students-and-education",
    },
    {
      imageSrc: "/images/education/knw2.svg",
      title: "Manage Difficult Emotions",
      desc: "Explore a deeper understanding of every emotion in your journey",
      link: "https://www.lissun.app/resources/manage-emotions",
    },
    {
      imageSrc: "/images/education/knw3.svg",
      title: "Calm your Body and Mind",
      desc: "Discover audio guidance to cultivate calm within yourself",
      link: "https://www.lissun.app/resources/meditate",
    },
  ];

  return (
    <>
      {/* <TopHeaderEdu /> */}
      <Head>
        <title>{"LISSUN for Students"}</title>
      </Head>
      <StudentForm show={showStudentForm} onClose={onClose} payType={payType} />

      <div className=" ">
        {paySuccessMsg && <PaymentSuccess close={closePaySuccessMsg} />}

        <div className=" bg-primary-100">
          <div className="flex items-center justify-between w-11/12 md:max-w-[1440px] mx-auto 2xl:px-20 py-3.5 lg:py-4  2xl:py-5  ">
            <div className={` `}>
              <Link href="https://www.lissun.app/">
                <a target="_blank">
                  <Image
                    src={`/images/logo-lissun-white.svg`}
                    width={"98"}
                    height={"31"}
                    objectFit="contain"
                    objectPosition="left"
                  />
                </a>
              </Link>
            </div>
            <div className="flex items-center justify-between  ml-14 md:ml-0 gap-1.5 mt-1.5 lg:gap-3.5">
              <Link href="https://play.google.com/store/apps/details?id=com.lissun.prod">
                <a target="_blank">
                  <Image
                    src={`/images/education/gplaybtn.svg`}
                    width={"134"}
                    height={"40"}
                    objectFit="contain"
                    objectPosition="left"
                  />
                </a>
              </Link>
              <Link href="https://apps.apple.com/in/app/lissun/id1620115148">
                <a target="_blank">
                  <Image
                    src={`/images/education/appleStorebtn.svg`}
                    width={"134"}
                    height={"40"}
                    objectFit="contain"
                    objectPosition="left"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>

        <EduHeroSection
          title="Empowering young minds for triumph"
          desc="LISSUN  is dedicated to guiding students through exam preparation, minimizing distractions, and unlocking their fullest potential for success in an exclusive approach tailored for Indian students."
          imgSrc="/images/education/Hero14.png"
          imgWidth={484}
          imgHeight={488}
          btnLabel="Get Started"
          handleClick={() => { }}
        />
        <EduMeetLissunErs
          title=" Meet the LISSUN-ers"
          desc=" We are a tech-driven emotional and mental wellness startup, providing individuals and enterprises with tailored support for their mental health needs. Our accomplished core team, including members from IIT, IIM, ISB, and NIMHANS, stands committed to transforming lives through accessible psychological support for every aspect of daily life."
          imgSrc="/images/Banner2.png"
          imgWidth={639}
          imgHeight={360}
          universityImages={universityImages}
        />

        <EduStudentSuicide
          title="Over 35,000 Indian students have died by suicide between 2019 and 2021, as per NCRB"
          imgSrc="/images/education/StudentStress1.svg"
          imgWidth={1160}
          imgHeight={610}
        />

        <EduLissunFeatures
          title="We LISSUN-ed to you"
          subTitle="We provide non-judgement expert support to help students tackle these mental health issues through"
          featureList={featureList}
        />
        <EduPersonalizedJourney
          title="Our upcoming features for every student's unique needs"
          personalJourneyList={personalJourneyList}
        />

        <EduPlanSection
          sectionTitle="Our Customized Packages"
          planList={planList}
        />
        <div className="bg-neutral-30 pb-8 md:pb-16">
          <Container type="type0" className="">
            <Heading
              type="subheading2"
              className="text-gray-800 mb-6 text-center max-w-[707px] mx-auto pt-8 lg:pt-20"
            >
              Our center of knowledge
            </Heading>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-9 ">
              {eduCenterOfKnowledgeData.map((list) => {
                return (
                  <EduCenterOfKnwoledge
                    imageSrc={list.imageSrc}
                    title={list.title}
                    desc={list.desc}
                    link={list.link}
                  />
                );
              })}
            </div>
          </Container>
        </div>

        <section className="pt-10 pb-0 md:pb-12 lg:pt-14  ">
          <Container type="type0" className={"pb-16 lg:pb-20"}>
            <div className="text-center  w-[95%]  mx-auto text-  ">
              <Heading className="!font-nunito text-xl md:text-3xl !font-semibold leading-6 md:leading-snug mx-auto">
                Partners and students that we've worked with
              </Heading>

              <p
                className="text-base lg:text-2xl font-normal max-w-[1000px] mx-auto  leading-6 md:leading-8 mt-4 lg:mb-14 mb-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                We collaborate with leading coaching institutions, helping
                numerous students in conquering their inner mental obstacles
                enabling them to reach their ultimate ambitious goals
              </p>

              <div className="flex gap-2 justify-center items-center">
                <Carousel
                  slidesToShow={4.5}
                  arrows={false}
                  autoPlay={true}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2.8,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1.8,
                      },
                    },
                    {
                      breakpoint: 575,
                      settings: {
                        slidesToShow: 1,
                      },
                    },
                  ]}
                >
                  {productImage.map((img) => {
                    return (
                      <Image
                        key={img}
                        src={img}
                        width={235}
                        height={150}
                        className=""
                        objectFit="contain"
                      />
                    );
                  })}
                </Carousel>
              </div>
              {/* <div className=" hidden  md:block">
                                <div className="flex gap-3 justify-center ">
                                    {productImage.map((img) => {
                                        return (
                                            <Image
                                                key={img}
                                                src={img}
                                                width={255}
                                                height={255}
                                                className=""
                                                objectFit="contain"
                                            />
                                        );
                                    })}
                                </div>
                            </div> */}
            </div>
          </Container>
        </section>

        <EduStudentTestimonial studentReview={studentReview} />

        <section className=" pb-0 mt-16 lg:mt-36 relative  ">
          <Container type="type0" className={""}>
            <div className="absolute bg-formBg  top-4 right-20"></div>
            <div>
              <div className="w-full">
                {showSuccessDialog && (
                  <ResponseSubmit close={() => setShowSuccessDialog(false)} />
                )}
                <FeedbackForm setShowSuccessDialog={setShowSuccessDialog} />
              </div>
            </div>
          </Container>
        </section>
        <section>
          <FooterBanner />
          <Footer />
        </section>
      </div>
    </>
  );
};

export default EducationLandingPage;
