import React from "react";
import Link from "next/link";
import Container from "@atoms/Container";

export default function Footer({ className }) {
  return (
    <footer
      className={`text-gray-600 text-sm overflow-hidden pt-10 pb-5 lg:pt-24 ${className}`}
    >
      <Container type="type0" className="px-0">
        <div className="flex-col lg:flex-row lg:flex justify-between">
          <div className="flex flex-col md:pb-0 md:px-0 pb-8">
            <Link href="https://www.lissun.app/">
              <img
                src="/images/education/LissunLOGO12.svg"
                className="self-start"
                alt="Lissun"
              ></img>
            </Link>
            <p className="font-Nunito font-normal text-sm text-monochromatic pt-6 uppercase">
              Mindeye Solutions Private Limited
            </p>
            <p className="pt-6 w-80 pr-5">
              6th Floor, Suncity Success Tower, Golf Course Ext Rd, Sector 65, Gurugram, Haryana 122005
            </p>
          </div>
          <div className="md:flex-row md:flex flex-col lg:w-2/3 lg:justify-around justify-between lg:mt-0 sm:mt-8 mt-5">
            <div className="flex flex-col md:pb-0 md:px-0 pb-8">
              <div className="text-lg font-bold">CONTACT US</div>
              <div className="mt-4 flex">
                <a href="mailto:reach-us@lissun.app">reach-us@lissun.app</a>
              </div>
              <div className="flex mt-2">
                <a href="tel:+919821688144">+91-9821688144</a>
              </div>
            </div>
            <div className="flex flex-col md:pb-0 md:px-0 pb-8">
              <div className="text-lg font-bold">Terms & Conditions</div>
              {/* <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/terms-and-conditions`}>
                <a className="mt-3">Terms & Conditions</a>
              </Link> */}
              <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/privacy-policy`}>
                <a className="mt-2">Privacy Policy</a>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/payment-policy`}>
                <a className="mt-2">Cancellation & Refund policy</a>
              </Link>
              {/* <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/about`}>
                <a className="mt-2">About Us</a>
              </Link> */}
            </div>
            <div className="flex flex-col md:pb-0 md:px-0 pb-8">
              <div className="text-lg font-bold">Useful Links</div>
              <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/connect-with-the-best-mental-health-counsellor-&-therapist-in-india`}>
                <a className="mt-3">Therapists</a>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_SITE_URL}/resources`}>
                <a className="mt-2">Resources</a>
              </Link>
            </div>
          </div>
        </div>
        <div className="font-Nunito font-normal text-sm text-monochromatic pt-6 text-center">
          Copyright{" "}
          <img
            src="/assets/images/copyright.png"
            alt="Copyright"
            className="relative inline-block"
          />{" "}
          2022 MINDEYE SOLUTIONS PRIVATE LIMITED
        </div>
      </Container>
    </footer>
  );
}
