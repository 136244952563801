import React from "react";

const Feedback = ({ feedBackImages }) => {
  return (
    <div>
      <div className="flex flex-wrap gap-12 ">
        <img
          src={feedBackImages[0]}
          className="absolute left-10 top-24  w-64 "
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[1]}
          className="absolute left-80 top-20  w-80"
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[2]}
          className="absolute right-72 top-16  w-56 "
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[3]}
          className="absolute left-0 top-72 w-[460px] "
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[4]}
          className="absolute right-96 top-60 w-80"
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[5]}
          className="absolute right-0 top-40 w-80 "
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[6]}
          className="absolute right-20 -bottom-0 w-80"
          data-aos="zoom-in"
        />
        <img
          src={feedBackImages[7]}
          className="absolute right-[450px] -bottom-4 w-72 "
          data-aos="zoom-in"
        />
      </div>
    </div>
  );
};

export default Feedback;
