import React from 'react'
import PropTypes from "prop-types";
const TextArea = ({
  register,
  error,
  label,
  id,
  className,
  labelClassName,
  setTime,
  ...inputProps
}) => {
  return (
    <div className="relative w-full mb-3 ">
      {setTime && <div id="time" className="mb-5 mt-5"></div>}

      <label
        className={`block text-blueGray-600 text-xs font-bold mb-2 float-left ${
          labelClassName ? labelClassName : "uppercase"
        }`}
        htmlFor="grid-password"
      >
        {label}
      </label>
      <textarea
        {...register(id)}
        id={id}
        {...inputProps}
        className={`border border-solid focus:border-0 border-blueGray-300 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${className} ${
          inputProps.disabled ? "bg-gray-200 cursor-not-allowed" : "bg-white"
        }`}
      />
      {error && (
        <div className="mt-4 text-red-600 text-[10px] font-medium	capitalize">
          {error.message}
        </div>
      )}
    </div>
  );
};

export default TextArea;

TextArea.propTypes = {
  register: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  setTime: PropTypes.bool,
};