import React from "react";
import Text from "@atoms/Text";
import router from "next/router";
function TopHeaderEdu() {
  return (
    <div className="h-14 flex items-center justify-center md:h-20 bg-primary-50 text-center w-full z-[1000]">
      
      <div
        onClick={() => {
          router.push("#feedbackSection");
        }}
      >
        <a className="block w-full max-w-[480px] md:max-w-[1100px] " target="_blank">
          
          <Text
            type="bodyStyleLarge"
            className=" marquee-animation text-center font-semibold text-[12px] md:text-lg text-white"
          >
            
            PREPARE TO EXCEL: Unlock Your Competitive Edge with LISSUN's
            Upcoming Solution for Students!
          </Text>
        </a>
      </div>
    </div>
  );
}
export default TopHeaderEdu;
