import {
    CREATE_UPDATE_STUDENT_DETAILS_FOR_EDUCATION,
    CREATE_EDU_STUDENT_PAYMENT_ORDER_ID
} from './url.service';
import * as HttpService from "./http.service";

const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const createUpdateStudent = (details) => {
    return HttpService.postWithOutAuth(CREATE_UPDATE_STUDENT_DETAILS_FOR_EDUCATION(), details);
};

export const createEduStudentOrder = (details) =>{
    return HttpService.postWithOutAuth(CREATE_EDU_STUDENT_PAYMENT_ORDER_ID, details)
}