import Container from "@atoms/Container";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import Image from "next/image";
import Link from "next/link";

const FooterBanner = ({ className }) => {
  return (
    <section className={`mt-10 lg:mt-0 ${className}`} id="download">
      <div className='max-w-[950px] mx-auto max-h-[344px] px-3 pt-10 md:pt-0'>
        <div className="bg-primary-100 rounded-3xl text-white lg:px-24 pb-6 md:pb-0 px-5 pt-5 md:pt-10 flex relative justify-between w-full">
          <div className="flex flex-col mt-8">
            <h1 className="text-3xl md:text-6xl  font-semibold text-white mb-3">
              Need help?
            </h1>
            <Text type="bodyStyleLarge" className="text-white mb-6">
              Reach us at <br />
              <a href="tel:+919821688144">+91-9821688144</a>
              <span className="mx-2">/</span>
              <a href="tel:+919821699488">+91-9821699488 </a> <br />
              to schedule therapies
            </Text>
            <div className="flex w-full">
              <Link href="https://play.google.com/store/apps/details?id=com.lissun.prod">
                <a className="mr-1" target="_blank" onClick={() => { }}>
                  <Image
                    src="/assets/images/google-play-badge.png"
                    alt="Google Play Store"
                    className=" object-contain"
                    width={160}
                    height={48}
                  />
                </a>
              </Link>
              <Link href="https://apps.apple.com/in/app/lissun/id1620115148">
                <a target="_blank" onClick={() => { }}>
                  <Image
                    src="/assets/images/AppStore.svg"
                    alt="App Store"
                    className=" object-contain"
                    width={160}
                    height={48}
                  />
                </a>
              </Link>
            </div>
          </div>
          <div className="md:flex hidden ">
            <Image
              src="/assets/images/bigMockup.png"
              alt="Google Play Store"
              className=" object-contain"
              width={207}
              height={311}
            />
            <img
              src="/assets/images/smallMockup.png"
              alt="Small phone Mockup"
              className="self-end absolute xl:right-38 sm:right-36"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterBanner;
