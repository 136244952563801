import React from "react";
import PropTypes from "prop-types";
import Container from "@atoms/Container";
import Carousel from "@molecules/Carousel";
import EduStudentReviewCard from "@molecules/EduStudentReviewCard";
import Heading from "@atoms/Heading";

const EduStudentTestimonial = ({ studentReview }) => {
    return (
        <section className="px-0 md:px-0">
            <Heading className="!font-nunito text-xl md:text-3xl !font-semibold leading-6 md:leading-snug mx-auto text-center pb-6 md:pb-8">
                Hear it from our customers
            </Heading>
            <Container type="type0" className={""}>
                <Carousel
                    slidesToShow={3}
                    centerMode={true}
                    arrows={true}
                    autoPlaySpeed={4000}
                    dots={false}
                    autoPlay={false}
                    iconBg="bg-transparent"
                    arrowPosition="topCenterMobBottom"
                    leftArrowIcon=<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                    >
                        <path
                            d="M30 56.875C44.825 56.875 56.875 44.825 56.875 30C56.875 15.175 44.825 3.125 30 3.125C15.175 3.125 3.12499 15.175 3.12499 30C3.12499 44.825 15.175 56.875 30 56.875ZM30 6.875C42.75 6.875 53.125 17.25 53.125 30C53.125 42.75 42.75 53.125 30 53.125C17.25 53.125 6.875 42.75 6.875 30C6.875 17.25 17.25 6.875 30 6.875Z"
                            fill="#D1D1DB"
                        />
                        <path
                            d="M23.75 31.875H38.75C39.775 31.875 40.625 31.025 40.625 30C40.625 28.975 39.775 28.125 38.75 28.125H23.75C22.725 28.125 21.875 28.975 21.875 30C21.875 31.025 22.725 31.875 23.75 31.875Z"
                            fill="#9C9CAF"
                        />
                        <path
                            d="M28.7563 39.3766C29.2313 39.3766 29.7063 39.2016 30.0813 38.8266C30.8062 38.1016 30.8062 36.9016 30.0813 36.1766L23.9063 30.0016L30.0813 23.8266C30.8062 23.1016 30.8062 21.9016 30.0813 21.1766C29.3563 20.4516 28.1563 20.4516 27.4313 21.1766L19.9312 28.6766C19.2062 29.4016 19.2062 30.6016 19.9312 31.3266L27.4313 38.8266C27.8063 39.2016 28.2813 39.3766 28.7563 39.3766Z"
                            fill="#9C9CAF"
                        />
                    </svg>
                    rightArrowIcon=<svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                    >
                        <path
                            d="M30 56.875C15.175 56.875 3.125 44.825 3.125 30C3.125 15.175 15.175 3.125 30 3.125C44.825 3.125 56.875 15.175 56.875 30C56.875 44.825 44.825 56.875 30 56.875ZM30 6.875C17.25 6.875 6.875 17.25 6.875 30C6.875 42.75 17.25 53.125 30 53.125C42.75 53.125 53.125 42.75 53.125 30C53.125 17.25 42.75 6.875 30 6.875Z"
                            fill="#D1D1DB"
                        />
                        <path
                            d="M36.25 31.875H21.25C20.225 31.875 19.375 31.025 19.375 30C19.375 28.975 20.225 28.125 21.25 28.125H36.25C37.275 28.125 38.125 28.975 38.125 30C38.125 31.025 37.275 31.875 36.25 31.875Z"
                            fill="#9C9CAF"
                        />
                        <path
                            d="M31.2437 39.3766C30.7687 39.3766 30.2937 39.2016 29.9187 38.8266C29.1938 38.1016 29.1938 36.9016 29.9187 36.1766L36.0937 30.0016L29.9187 23.8266C29.1938 23.1016 29.1938 21.9016 29.9187 21.1766C30.6437 20.4516 31.8437 20.4516 32.5687 21.1766L40.0688 28.6766C40.7938 29.4016 40.7938 30.6016 40.0688 31.3266L32.5687 38.8266C32.1937 39.2016 31.7187 39.3766 31.2437 39.3766Z"
                            fill="#9C9CAF"
                        />
                    </svg>
                    responsive={[
                        {
                            breakpoint: 1025,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                >
                    {studentReview.map((review, index) => (
                        <EduStudentReviewCard

                            key={index}
                            title={review.title}
                            desc={review.desc}
                            name={review.name}
                            profession={review.profession}
                            imgSrc={review.imgSrc}
                            imgWidth={review.imgWidth}
                            imgHeight={review.imgHeight}
                        />
                    ))}
                </Carousel>
            </Container>
        </section>
    );
};

EduStudentTestimonial.propTypes = {
    studentReview: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            desc: PropTypes.string,
            imgSrc: PropTypes.string,
            imgWidth: PropTypes.number,
            imgHeight: PropTypes.number,
        })
    ),
};

export default EduStudentTestimonial;

