import Heading from "@atoms/Heading";
import React from "react";
import Text from "@atoms/Text";
import Button from "@atoms/Button";
function ResponseSubmit({ close }) {
  return (
    <div className="dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-40 z-[100]">
        <div className=" relative w-11/12 md:max-w-[410px] p-6 bg-white border-gray-400 border ">
              <div className="flex items-center">
                <img
                  src="/images/CheckCircle.svg"
                  className="cursor-pointer w-5 h-5 mt-1 mr-3"
                />
                <Heading
                  type="h4"
                  className="w-full font-Nunito text-base mb-1 font-bold  mt-2"
                >
                  Response Submitted Successfully!
                </Heading>
              </div>
              <div className={``}>
                <Text
                  textStyle="bodyStyleLarge"
                  className="text-sm leading-5 font-normal ml-8 mb-20"
                >
                  Thank you for your response, our team will get in touch with you
                  shortly!
                </Text>
              </div>
              <Button
                type="largeContained"
                className=" absolute bottom-2 right-6 py-2 mb-4 px-6 "
                onClick={close}
              >
                Ok
              </Button>
            </div>
    </div>
   
  );
}

export default ResponseSubmit;


