import Heading from "@atoms/Heading";
import Button from "@atoms/Button";

import React from "react";
import Text from "@atoms/Text";
import { useForm } from "react-hook-form";
import { yupValidator } from "../../lib/yup-validator";
import * as yup from "yup";
import InputGroup from "@molecules/InputGroup";
import { createUpdateStudent, createEduStudentOrder } from "@services/education.service";

function StudentForm({ onClose, show, type, payType }) {
  if (!show) return null

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupValidator(
      yup.object().shape({
        name: yup
          .string()
          .required("Name is mandatory")
          .matches(/^[a-zA-Z ]*$/, "Please Enter Valid Name"),
        phoneNo: yup
          .string()
          .required("Phone Number is mandatory")
          .matches(/^[0-9]{10}$/, "Invalid phone Number"),
        email: yup
          .string()
          .email(),
        scPhoneNo: yup
          .string(),
      })
    ),
  });

  const onSubmit = (data, e) => {
    createUpdateStudent(data).then((res) => {
      if (res.status) {
        setValue("name", "");
        setValue("phoneNO", "");
        setValue("email", "");
        setValue("scPhoneNo", "");
        onClose(true);
        createEduStudentOrder({ eduStudentId: res.entity.id, type: payType }).then((res) => {
          console.log(res)
          window.open(`/pay/${res.entity.id}`)
        })
      }
    });
  };




  return (
    <div
      className={`dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-black bg-opacity-40 z-[100] px-4 md:px-0 `}
    >
      <div
        className={`relative p-6 border-gray-400 border w-11/12 md:max-w-[440px] bg-white mx-auto rounded-xl  `}
      >
        <Heading
          type="h4"
          className="w-full font-Nunito text-base md:text-2xl mb-1 font-medium  text-center mt-2"
        >
          Please fill in your details
        </Heading>
        <Text
          textStyle="bodyStyleSmall"
          className="text-xs md:text-sm mb-8 font-normal text-center"
        >
          Please fill in your details below and our team will reach out to you
          at the earliest.
        </Text>
        <div className="grid grid-cols-1 gap-5 mt-8 mb-6">
          <InputGroup
            type="text"
            label="Full Name*"
            register={register}
            errors={errors}
            inputClassName="bg-white   border-gray-400  outline-none rounded-lg border "
            name="name"
            placeholder={"Enter Full Name"}
          />
          <InputGroup
            type="number"
            label="Phone Number*"
            register={register}
            errors={errors}
            inputClassName="bg-white   border-gray-400  outline-none rounded-lg border "
            name="phoneNo"
            placeholder={"Enter Phone Number"}
          />
          <InputGroup
            type="text"
            label="Email"
            register={register}
            errors={errors}
            inputClassName="bg-white   border-gray-400  outline-none rounded-lg border"
            name="email"
            placeholder={"Enter Email"}
          />
          <InputGroup
            type="text"
            label="Alternative Number"
            register={register}
            errors={errors}
            inputClassName="bg-white   border-gray-400  outline-none rounded-lg border"
            name="scPhoneNo"
            placeholder={"Enter Alternative Number"}
          />

        </div>

        <div className="w-full mt-10 flex justify-end ">
          <Button
            type="largeContained"
            onClick={handleSubmit(onSubmit)}
            className="  px-24   "
          >
            Submit
          </Button>
        </div>
        <img
          src="/images/Close.svg"
          className=" cursor-pointer absolute top-4 right-3 "
          onClick={() => onClose(false)}
        />
      </div>
    </div>
  );
}

export default StudentForm;
