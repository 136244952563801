import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import Container from "@atoms/Container";
import Text from "@atoms/Text";
import Heading from "@atoms/Heading";
import Carousel from "@molecules/Carousel";

const EduPersonalizedJourney = ({
    title = "Our upcoming features for every student's unique needs",
    personalJourneyList,
}) => {
    return (
        <section className="bg-white pt-4 md:pt-8  pb-8 lg:pb-16  ">
            <Container type="type0" className={""}>
                <div className="text-center   w-full mx-auto ">
                    <Heading
                        type="subheading2"
                        className="text-gray-800 mb-6 text-center max-w-[707px] mx-auto mt-8"
                    >
                        {title}
                    </Heading>
                    <div className="md:grid-cols-4 grid-cols-2 mt-16 gap-2 hidden md:grid">
                        {personalJourneyList.map((list, index) => (
                            <div key={index} className="mb-4 lg:mb-10">
                                <Text type="bodyStyleMedium " className="font-semibold">
                                    {list.title}
                                </Text>
                                <Image
                                    src={list.icon}
                                    objectFit="cover"
                                    width={255}
                                    height={255}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mt-16 md:hidden block">
                        <Carousel
                            slidesToShow={1}
                            dots={true}
                            arrows={false}
                            autoPlay={true}
                            responsive={[
                                {
                                    breakpoint: 767,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                    },
                                },
                                {
                                    breakpoint: 576,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    },
                                },
                            ]}
                        >
                            {personalJourneyList.map((list, index) => (
                                <div key={index} className="mb-4">
                                    <Text
                                        type="bodyStyleExtraLarge"
                                        className=""
                                    >
                                        {list.title}
                                    </Text>
                                    <Image
                                        src={list.icon}
                                        objectFit="cover"
                                        width={255}
                                        height={255}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default EduPersonalizedJourney;

EduPersonalizedJourney.propTypes = {
    title: PropTypes.string,
    personalJourneyList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            icon: PropTypes.string,
        })
    ),
};