import React from "react";
import PropTypes from "prop-types";
import Container from "@atoms/Container";
import Heading from "@atoms/Heading";
import Carousel from "@molecules/Carousel";
import EduPlan from "@molecules/EduPlan";

const EduPlanSection = ({
    sectionTitle = "Our Customized Packages",
    planList,
}) => {
    return (
        <section className="PACKAGES  bg-morning-glory py-4 md:py-20 ">
            <Container type="type0" className={""}>
                <div className="text-center   w-full mx-auto  ">
                    <Heading
                        type="subheading1"
                        className="max-w-[850px] mx-auto mt-8 mb-6 md:mt-0 md:mb-0"
                    >
                        {sectionTitle}
                    </Heading>
                    <div className=" lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-5 hidden md:grid">
                        {planList.map((list, index) => (
                            <EduPlan
                                key={index}
                                planName={list.planName}
                                planPrice={list.planPrice}
                                planFeatures={list.planFeatures}
                                appFeatures={list.appFeatures}
                                onClickBuyNow={list.onClickBuyNow}
                            />
                        ))}
                    </div>
                    <div className="md:hidden block">
                        <Carousel
                            slidesToShow={1}
                            dots={true}
                            arrows={false}
                            autoPlay={true}
                        >
                            {planList.map((list, index) => (
                                <EduPlan
                                    key={index}
                                    planName={list.planName}
                                    planPrice={list.planPrice}
                                    planFeatures={list.planFeatures}
                                    appFeatures={list.appFeatures}
                                    onClickBuyNow={list.onClickBuyNow}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default EduPlanSection;

EduPlanSection.propTypes = {
    sectionTitle: PropTypes.string,
    planList: PropTypes.arrayOf(
        PropTypes.shape({
            planName: PropTypes.string,
            planPrice: PropTypes.number,
            planFeatures: PropTypes.arrayOf(
                PropTypes.shape({
                    features: PropTypes.string,
                })
            ),
            appFeatures: PropTypes.arrayOf(
                PropTypes.shape({
                    features: PropTypes.string,
                })
            ),
            onClickBuyNow: PropTypes.func,
        })
    ),
};
