import React from "react";
import PropTypes from "prop-types";
import Container from "@atoms/Container";
import Heading from "@atoms/Heading";
import Text from "@atoms/Text";
import Image from "next/image";

const EduMeetLissunErs = ({
    title = "Meet the LISSUN-ers",
    desc = "",
    imgSrc = "/images/Banner2.png",
    imgWidth = 639,
    imgHeight = 360,
    universityImages,
}) => {
    const myLoader = ({ src }) => src;
    return (
        <section className="bg-neutral-30 py-6 md:pt-12 md:py-0 w-full ">
            <Container type="type0" className={"AboutBanner"}>
                <div className="lg:flex w-full gap-0 md:gap-12  ">
                    <div className="p-2 w-full flex-none max-w-[610px]" data-aos="fade-left">
                        <Heading type="subheading2" className="text-gray-800r ">
                            {title}
                        </Heading>

                        <Text
                            type="bodyCaption6"
                            className="text-gray-800 mt-3 md:mt-6"
                        >
                            {desc}
                        </Text>
                        <div className="md:max-w-[464px] py-8 px-8 flex gap-5 md:gap-12">
                            {universityImages.map((img) => {
                                return (
                                    <Image
                                        loader={myLoader}
                                        key={img}
                                        src={img}
                                        width={100}
                                        height={80}
                                        style={{ padding: "20px" }}
                                        objectFit="contain"
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div>
                        <div className="flex shadow-xl">
                            <Image
                                loader={myLoader}
                                src={imgSrc}
                                width={imgWidth}
                                height={imgHeight}
                                className="rounded-xl"
                                objectFit="cover"
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default EduMeetLissunErs;

EduMeetLissunErs.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    imgSrc: PropTypes.string,
    imgWidth: PropTypes.number,
    imgHeight: PropTypes.number,
    universityImages: PropTypes.arrayOf(
        PropTypes.shape({
            img: PropTypes.string,
        })
    ),
};